import * as React from "react";
import Redirect from "../../../../components/redirect";
//--------------------------------------------------

  const url = "https://www.hk01.com/%E8%97%9D%E6%96%87/670194/%E8%B7%AF%E9%81%8E%E5%8C%97%E8%A7%92-%E7%82%BA%E8%88%8A%E5%8D%80%E6%B7%BB%E6%B4%BB%E5%8A%9B-%E7%B7%AC%E6%87%B7%E6%98%94%E6%97%A5%E6%B3%B3%E6%A3%9A%E7%A2%BC%E9%A0%AD%E8%A8%AD%E9%A6%96%E5%80%8B%E6%B5%B7%E4%B8%8A%E8%97%9D%E8%A1%93%E8%A3%9D%E7%BD%AE?fbclid=IwAR0HFRSnL3Z2-jRwtatb8S2vY7-1_9Dixdr6Rf6zxu_VZcFKf7mt2xIMqcA";

//--------------------------------------------------
// common config below this line, no need to change
const IndexPage = () => ( <Redirect goto_url={url} /> );
export default IndexPage;
